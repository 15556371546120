import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { Application } from '../enums/enums';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { Message } from './message';
@Injectable(
    {
        providedIn: 'root'
    }
)
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private readonly httpService: HttpService,
        private readonly authService: AuthService,
        private readonly toastService: ToastrService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case Application.FOUR_HUNDRED_ONE:
                case Application.FOUR_HUNDRED_THREE:
                    this.authService.clearLocalStorage();
                    this.router.navigate(['']);
                    if (err.error && err.error.message) {
                        this.toastService.error(err.error.message);
                    }
                    break;
                case Application.FIVE_HUNDRED:
                case Application.FIVE_HUNDRED_TWO:
                    if (err.error && err.error.message) {
                        this.toastService.error(err.error.message);
                    } else {

                        this.toastService.error(Message.API_500_ERROR);
                    }
                    break;
                case Application.ZERO:
                    this.toastService.error(Message.WENT_WRONG);
                    break;
                default:
                    if (err.error && err.error.message) {
                        this.toastService.error(err.error.message);
                    }
                    break;
            }
            // this.httpService.loaderToggle(false);
            const error = err.error;
            return throwError(error);
        }));
    }
}
