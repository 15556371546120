import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { SharedService } from '../../service/shared.service';
@Component({
  selector: 'app-thumbnails',
  templateUrl: './thumbnails.component.html',
  styleUrls: ['./thumbnails.component.css']
})
export class ThumbnailsComponent implements OnInit, OnChanges {
  @ViewChild('pdf', { static: false }) pdfContent!: ElementRef;
  @Input() showLoader = false;
  @Output() url = new EventEmitter();
  @Input() contentUrl = '';
  loader = false;
  constructor(private readonly authService: AuthService, private readonly sharedService: SharedService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void { }

  // tslint:disable-next-line:typedef
  async contentUpload(event: any) {
    this.loader = true;
    this.authService.getFileToUrl(event)
      .then(res => {
        this.uploadFile(event.target.files[0]);
      }, error => {
        this.loader = false;
      });
  }
  resetFileNativeEl(): void {
    this.pdfContent.nativeElement.value = '';
  }

  uploadFile(file: any): void {
    const input = new FormData();
    input.append('file', file);
    this.sharedService.uploadFile(input)
      .subscribe(res => {
        this.showLoader = false;
        this.loader = false;
        this.url.emit(res);
        this.contentUrl = res;
      }, error => {
        this.loader = false;
        this.showLoader = false;
      });
  }

  removeImage(): void {
    this.loader = true;
    const url = this.contentUrl.split('/');
    this.sharedService.removeImage({ imagePath: url[url.length - 1] })
      .subscribe(res => {
        this.loader = false;
        this.contentUrl = '';
        this.url.emit('');
      }, error => { this.loader = false; });
  }


}
