import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../core/auth.service';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

const formOptions = {
  headers: new HttpHeaders()
};

const baseApiURL: string = environment.baseApiURL;

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  loaderId = '#reqestLoader';
  public diaplayLoader$ = new Subject<any>();
  public diaplayBottomLoader$ = new Subject<any>();
  constructor(
    private readonly http: HttpClient) { }

  /**
   * @description For handle http request.
   * @param type request type
   * @param apiName API end point
   * @param showLoader loader status
   * @param data  request body
   */
  httpRequest(
    type: string,
    apiName: string,
    showLoader: boolean,
    showBottomLoader = false,
    data?: any
  ): Observable<any> {

    if (showLoader === true) {
      this.loaderToggle(true);
      this.loaderBottomToggle(false);
    }

    if (showBottomLoader === true) {
      this.loaderBottomToggle(true);
      this.loaderToggle(false);
    }

    if (AuthService.getToken()) {
      const tokenObj = AuthService.getToken();
      httpOptions.headers = httpOptions.headers.set('Authorization', String(tokenObj));
    }
    let returnObj: any = '';
    switch (type) {
      case 'GET':
        return this.http.get(`${baseApiURL + apiName}`, httpOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            if (showBottomLoader) {
              this.loaderBottomToggle(false);
            }
            returnObj = res;
            return res;
          }),
          );
      case 'POST':
        return this.http.post(`${baseApiURL + apiName}`, data, httpOptions)
          .pipe(map(res => {
            returnObj = res;
            return res;
          }));
      case 'PUT':
        return this.http.put(`${baseApiURL + apiName}`, data, httpOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }));
      case 'DELETE':
        return this.http.delete(`${baseApiURL + apiName}`, httpOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }),
          );
      case 'PATCH':
        return this.http.patch(`${baseApiURL + apiName}`, data, httpOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }));
    }
    return returnObj;
  }

  /**
   * @description Multipart request (form data)
   * @param type  Request type
   * @param apiName  API end point
   * @param showLoader Loader status
   * @param data Request data.
   */
  formRequest(
    type: string,
    apiName: string,
    showLoader: boolean,
    data?: any
  ): Observable<any> {
    if (showLoader === true) {
      this.loaderToggle(true);
    }
    if (AuthService.getToken()) {
      const tokenObj = AuthService.getToken();
      formOptions.headers = formOptions.headers.set('Authorization', 'Bearer ' + String(tokenObj));
    }
    let returnObj: any = '';
    switch (type) {
      case 'GET':
        return this.http.get(`${baseApiURL + apiName}`, formOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }),
          );
      case 'POST':
        return this.http.post(`${baseApiURL + apiName}`, data, formOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }));
      case 'PUT':
        return this.http.put(`${baseApiURL + apiName}`, data, formOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }));
      case 'DELETE':
        return this.http.delete(`${baseApiURL + apiName}`, formOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }),
          );
      case 'PATCH':
        return this.http.patch(`${baseApiURL + apiName}`, data, formOptions)
          .pipe(map(res => {
            if (showLoader) {
              this.loaderToggle(false);
            }
            returnObj = res;
            return res;
          }));
    }
    return returnObj;
  }

  /**
   * @description manage loader status on api call
   */
  public loaderToggle(val: boolean): void {
    this.diaplayLoader$.next(val);
  }

  public loaderBottomToggle(val: boolean): void {
    this.diaplayBottomLoader$.next(val);
  }
}
