export const Application = {
    TOKEN: 'token',
    FOUR_HUNDRED_ONE: 401,
    FOUR_HUNDRED_THREE: 403,
    FIVE_HUNDRED: 500,
    FIVE_HUNDRED_TWO: 502,
    ZERO: 0,
    LIMIT: 10,
    ONE: 1,
    USER: 'user',
    ROLE_ADMIN: 'admin',
    ROLE_TEACHER: 'teacher',
    TEN: 10,
    TWENTY_FIVE: 25
};
