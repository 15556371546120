<app-loader *ngIf="loader"></app-loader>
<div class="form-control upload-container-div" *ngIf="!contentUrl">
    <div class="icon_container">
        <img class="upload_icon" src="assets/image/upload_icon.png" alt="not fount">
        <p>Upload Thumbnail Image</p>
    </div>
     <input #pdf type="file"  accept="image/x-png,image/gif,image/jpeg"
        (change)="contentUpload($event)">
</div>
<div *ngIf="contentUrl"  [style.background]="'url(' + contentUrl + ')'" class="form-control upload-container-div background-control">
   <button class="remove_botton" (click)="removeImage()" type="button">Remove</button>
</div>
