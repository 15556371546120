import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { HttpService } from 'src/app/core/http.service';
@Injectable()
export class SharedService {
    constructor(private readonly httpService: HttpService) { }

    // tslint:disable-next-line:typedef
    getList() {
        const responseObj = [];
        const classUrl = `content/getClass`;
        const subjectUrl = `content/getSubject`;
        const boardUrl = `content/getBoard`;
        const chapterUrl = `content/getChapter`;
        const courseUrl = `content/getCourse`;
        const classList = this.httpService.httpRequest('GET', classUrl, true, true);
        responseObj.push(classList);
        const subList = this.httpService.httpRequest('GET', subjectUrl, true, true);
        responseObj.push(subList);
        const boardList = this.httpService.httpRequest('GET', boardUrl, true, false);
        responseObj.push(boardList);
        const chapterList = this.httpService.httpRequest('GET', chapterUrl, true, false);
        responseObj.push(chapterList);
        const courseList = this.httpService.httpRequest('GET', courseUrl, true, false);
        responseObj.push(courseList);
        return forkJoin(responseObj);
    }

    // tslint:disable-next-line:typedef
    convertQueryString(params: any) {
        const queryString = Object.keys(params).map((key) => {
            return key + '=' + params[key];
        }).join('&');
        return queryString;
    }

    // tslint:disable-next-line:typedef
    uploadFile(data: any) {
        const url = 'question/upload';
        return this.httpService.formRequest('POST', url, false, data);
    }
    // tslint:disable-next-line:typedef
    removeImage(obj: any) {
        const url = 'removeMedia';
        return this.httpService.formRequest('POST', url, false, obj);
    }

}
