import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Application } from 'src/app/enums/enums';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() totalRecord = 100;
  limit = Application.LIMIT;
  @Output() pageChange = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  changeEvent(event: any): void {
    this.pageChange.emit(event);
  }

}
