import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Application } from 'src/app/enums/enums';
import { SharedService } from '../../service/shared.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  chapterList: Array<any> = [];
  subjectList: Array<any> = [];
  boardList: Array<any> = [];
  classList: Array<any> = [];
  tempSubjectList: Array<any> = [];
  tempChapterList: Array<any> = [];
  tempCourseList: Array<any> = [];
  courseList:any = [];
  @Input() type = '';
  @Input() search = '';
  @Input() isPagination = false;
  @Output() filterEvent = new EventEmitter();
  filterForm!: FormGroup;
  application = Application;
  constructor(private readonly sharedService: SharedService, private readonly fb: FormBuilder) { }

  ngOnInit(): void {
    this.getList();
    this.formIns();
    this.filterForm?.valueChanges.subscribe(res => {
      this.filterEvent.emit(this.filterForm?.value);
    });
  }

  formIns(): void {
    this.filterForm = this.fb.group({
      search: [null],
      subjectId: [null],
      classId: [null],
      boardId: [null],
      chapterId: [null],
      courseId:[null],
      userType:[null],
      studentType:[null],
      page: [this.application.ONE],
      limit: [this.application.LIMIT],
      isPagination: [this.isPagination]
    });
  }
  getList(): void {
    this.sharedService.getList()
      .subscribe(res => {
        this.classList = res[0].data;
        this.boardList = res[2].data;


        this.tempSubjectList = res[1].data;
  
        
        this.tempChapterList = res[3].data;
        this.tempCourseList = res[4].data;
      });
  }

  // tslint:disable-next-line:typedef
  clearFilter() {
    this.filterForm?.reset();
  }

  onSelect(event: any) {
    let boardId = this.filterForm.value.boardId;
    let classId = this.filterForm.value.classId;
    let courseId = this.filterForm.value.courseId;
    let subjectId = this.filterForm.value.subjectId;

    if(boardId && classId) 
      this.courseList = this.tempCourseList.filter((d: any) => d.boardId._id === boardId && d.classId._id === classId)

    if(boardId && classId && courseId)
      this.subjectList = this.tempSubjectList.filter((d: any) => d.boardId._id === boardId && d.classId._id === classId && d.courseId._id === courseId)
    
    if(boardId && classId && courseId && subjectId)
      this.chapterList = this.tempChapterList.filter((d: any) => d.boardId._id === boardId && d.classId._id === classId && d.courseId._id === courseId && d.subjectId._id === subjectId)
  }

}
