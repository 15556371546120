import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './angular-material/material.module';
import { LoaderComponent } from './components/loader/loader.component';
import { FilterComponent } from './components/filter/filter.component';
import { SharedService } from './service/shared.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ThumbnailsComponent } from './components/thumbnails/thumbnails.component';
@NgModule({
  declarations: [LoaderComponent, FilterComponent, PaginationComponent, ThumbnailsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule, ReactiveFormsModule,
    MatPaginatorModule
  ],
  exports: [LoaderComponent, FilterComponent, PaginationComponent, ThumbnailsComponent],
  providers: [SharedService]
})
export class SharedModule { }
