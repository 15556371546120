import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '../enums/enums';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private readonly router: Router) { }

  /**
   * @description get JWT Token from local storage.
   */
  // tslint:disable-next-line:typedef
  static getToken() {
    return localStorage.getItem(Application.TOKEN);
  }

  /**
   * @description check user login status
   */
  isUserLoggedIn(): boolean {
    const loadedUser = localStorage.getItem(Application.TOKEN);
    if (loadedUser) {
      return true;
    }
    return false;
  }


  /**
   * @description logout user
   */
  // tslint:disable-next-line:typedef
  logout() {
    localStorage.removeItem(Application.TOKEN);
    this.router.navigate(['']);

  }

  // tslint:disable-next-line:typedef
  clearLocalStorage() {
    localStorage.clear();
  }
  // tslint:disable-next-line:typedef
  setToken(token: string) {
    localStorage.setItem(Application.TOKEN, token);
  }

  // tslint:disable-next-line:typedef
  setData(data: any) {
    localStorage.setItem(Application.USER, JSON.stringify(data));
  }
  // tslint:disable-next-line:typedef
  getData() {
    const userData = localStorage.getItem(Application.USER);
    return userData ? JSON.parse(userData) : null;
  }
  /**
  * @description get file to base 64 url
  * @param file file to conver
  */
  // tslint:disable-next-line:typedef
  getFileToUrl(event: any) {
    return new Promise((resolve, reject) => {
      if (event.target.files.length >= 0) {
        const fileToUpload = event.target.files[Application.ZERO];
        if (event.target.files && fileToUpload) {
          const reader = new FileReader();
          reader.readAsDataURL(fileToUpload);
          reader.onload = () => {
            resolve(reader.result);
          };
        }
      }
    });
  }
}
