<div class="add-form">
    <form name="testForm" [formGroup]="filterForm">
        <div>
            <button type="button" class="clear-filter" (click)="clearFilter()"><i class="fa fa-filter" aria-hidden="true"></i> Clear Filters</button>
        </div>
        <div class="form-control">
            <label for="search">{{search}}</label>
            <div class="search">
                <input formControlName="search" id="search" type="text" class="searchTerm" placeholder="What are you looking for?">
                <button type="submit" class="searchButton">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
        <div class="form-control item-col pr10">
            <label for="board">Filter by board</label>
            <select formControlName="boardId" id="board" class="select-box" (change)="onSelect($event)">
                <option [value]="null" disabled>Select</option>
                <option [value]="type?._id" *ngFor="let type of boardList">{{type?.name}}</option>
            </select>
        </div> 
        <div class="form-control item-col" *ngIf="type!=='teacher'">
            <label for="classId">Filter by class</label>
            <select formControlName="classId" id="classId" class="select-box" (change)="onSelect($event)">
                <option [value]="null" disabled>Select</option>
                <option [value]="type?._id" *ngFor="let type of classList">{{type?.name}}</option>
            </select>
        </div>
        <div class="form-control item-col" *ngIf="type!=='teacher' && type !== 'user-slot'">
            <label for="courseId">Filter by course</label>
            <select formControlName="courseId" id="courseId" class="select-box" (change)="onSelect($event)">
                <option [value]="null" disabled>Select</option>
                <option [value]="course?._id" *ngFor="let course of courseList">{{course?.name}}</option>
            </select>
        </div>
        <div class="form-control item-col pr10" *ngIf="type!=='teacher' && type!=='user' && type !== 'user-slot'">
            <label for="subjectId">Filter by subject</label>
            <select formControlName="subjectId" id="subjectId" class="select-box" (change)="onSelect($event)">
                <option [value]="null">Select</option>
                <option [value]="type?._id" *ngFor="let type of subjectList">{{type?.name}}</option>
            </select>
        </div>
        <div class="form-control item-col" *ngIf="type!=='chapter' && type!=='teacher' && type!=='user' && type !== 'user-slot'">
            <label for="chapterId">Filter by chapter</label>
            <select formControlName="chapterId" id="chapterId" class="select-box">
                <option [value]="null" disabled>Select</option>
                <option [value]="type?._id" *ngFor="let type of chapterList">{{type?.name}}</option>
            </select>
        </div>
        <div class="form-control item-col" *ngIf="type=='user'">
            <label for="userType">Filter by user</label>
            <select formControlName="userType" id="userType" class="select-box">
                <option [value]="null" disabled>Select</option>
                <option value="student">Student</option>
                <option value="teacher">Teacher</option>
            </select>
        </div>
        <div class="form-control item-col" *ngIf="type=='user' && filterForm.controls.userType.value == 'student'">
            <label for="studentType">Filter by student type</label>
            <select formControlName="studentType" id="studentType" class="select-box">
                <option [value]="null" disabled>Select</option>
                <option value="premium">Premium</option>
                <option value="non-premium">Non Premium</option>
            </select>
        </div>
        
    </form>
</div>
