import { Injectable } from '@angular/core';
import {
    Router,
    Route,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

// import { Application } from '@app/enums/application.enum';

// import { USER_ROLE } from '@shared/static/constraints';

// // Service
import { AuthService } from '../auth.service';
// import { CommonService } from '@shared/services/common.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
        // private readonly commonService: CommonService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (!this.authService.isUserLoggedIn()) {
            this.router.navigate(['']);
            return false;
        }
        // const role = this.commonService.getLocalStorageItem(Application.USER_TYPE);
        // const viewAsData = JSON.parse(this.commonService.getLocalStorageItem(Application.VIEW_AS));

        // if (state.url.startsWith('/home/manage-integration') && role === USER_ROLE.USER_TYPE_EMPLOYEE) {
        //     this.router.navigate(['/home/dashboard']);
        //     return false;
        // }

        // if (state.url.startsWith('/home/manage-owner') && role !== USER_ROLE.USER_TYPE_ADMIN) {
        //     this.router.navigate(['/home/dashboard']);
        //     return false;
        // }

        // if (state.url.startsWith('/home/manage-employee') && role !== USER_ROLE.USER_TYPE_COMPANY_OWNER && !viewAsData) {
        //     if (role === USER_ROLE.USER_TYPE_ADMIN) {
        //         this.router.navigate(['/home/dashboard/control']);
        //     } else {
        //         this.router.navigate(['/home/dashboard']);
        //     }
        //     return false;
        // }
        return true;

    }

}
